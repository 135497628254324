import { Button } from 'primereact/button'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate = useNavigate()
  const waitlistUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdDnlZ5kZe7dgk9RCzy1-csgiZ3GBIRDnBHxYx83zynjhv9Fg/viewform?embedded=true'
  return (
    <div className='home-container'>
      <h1>
        where <span>sports</span> meets learning
      </h1>
      <p className='sub-text-home'>Bringing together knowledge, passion, and the spirit of sports.</p>
      <p className='f600 mt-24'>Be the first to experience the future of sports learning.</p>
      <Button label="Join Waitlist" onClick={() => window.open(waitlistUrl, '_blank')} rounded className='joinlist-btn' />
    </div>
  )
}

export default Home